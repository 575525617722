const GDP_CHECKS = [
  // {
  //   id: 1,
  //   type: 'global',
  //   name: 'Has Required Sections',
  //   prompt:
  //     'Does the document have the following sections? Purpose, Scope, References, Change Request, Definitions and Acronyms, Document Approvers, Release Test Strategy, Out of Scope, Summary of Defects/Anomalies/Features, Acceptance Criteria, Hardware and Software Environment, Tools, Datasets and Equipment Required for Testing, Test Deliverables, Planned Deviation?',
  // },
  {
    id: 2,
    type: 'global',
    name: 'Has Required Approvals',
    prompt:
      'Are the following approvers included? Software Test Engineer, Subject Matter Expertise, Software Quality Engineer and Software Engineer',
  },
  {
    id: 3,
    type: 'global',
    name: 'Has No Grammatical Errors',
    prompt:
      'Identify only the grammatical issues that result in confusion or make the text hard to understand. Ignore minor errors and stylistic preferences. Focus only on the parts of the text that are incomprehensible or could confuse the reader.',
  },
  {
    id: 4,
    type: 'global',
    name: 'Has No Undefined Terms',
    prompt:
      'Are all product specific terms defined in the Definitions and Acronyms section?',
  },
  // {
  //   id: 5,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Clear Purpose',
  //   prompt: 'Is the purpose of the document clearly stated?',
  // },
  // {
  //   id: 6,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Clear Scope',
  //   prompt:
  //     'Is the scope of the document clearly defined and applicable to software verification activities?',
  // },
  // {
  //   id: 7,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Roles Specified',
  //   prompt:
  //     'Are the roles and responsibilities of the Software Test Engineer, Software Quality Engineer, and Subject Matter Expert specified?',
  // },
  // {
  //   id: 8,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Definitions Referenced',
  //   prompt:
  //     'Are the definitions and acronyms used in the document defined and referenced to the Regulatory MedDev Glossary?',
  // },
  // {
  //   id: 9,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Correct Citations',
  //   prompt:
  //     'Are the references to other documents, procedures, and standards listed and correctly cited?',
  // },
  // {
  //   id: 10,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Has Comprehensive Software Test Plan',
  //   prompt:
  //     'Is the Software Test Plan (STP) included and comprehensively outlining the scope, purpose, and references for accurate comprehension of release planning activities?',
  // },
  // {
  //   id: 11,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Approvers Listed',
  //   prompt: 'Are the document approvers listed and correct?',
  // },
  // {
  //   id: 12,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Software Release Test Strategy Details',
  //   prompt:
  //     'Are the software release test strategy details included, outlining verification activities, regression testing, and out-of-scope items?',
  // },
  // {
  //   id: 13,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Acceptance Criteria Specified',
  //   prompt:
  //     'Are the acceptance criteria for initiating software verification activities specified?',
  // },
  // {
  //   id: 14,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Hardware and Software Test Environment Configurations',
  //   prompt:
  //     'Are the hardware and software environment configurations required for testing identified and documented?',
  // },
  // {
  //   id: 15,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Equipment, Datasets, and Tools specified',
  //   prompt:
  //     'Are the equipment, datasets, and tools required for verification testing identified, documented, and assessed?',
  // },
  // {
  //   id: 16,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has All Applicable Test Deliverables Listed',
  //   prompt:
  //     'Are the test deliverables applicable to the release listed, including the approved software verification plan, software test protocol, Software Test Plan, and software verification report?',
  // },
  // {
  //   id: 17,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Planned Deviations Documented',
  //   prompt:
  //     'Are the planned deviations from the SVP and/or SW Verification Work Instruction documented?',
  // },
  // {
  //   id: 18,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Unplanned Deviations Documented',
  //   prompt:
  //     'Are the unplanned deviations from the STP documented in the SVR, including a description of the task, rationale, and assessment of the effect of the deviation?',
  // },
  // {
  //   id: 19,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Software Maturity Levels Assigned',
  //   prompt:
  //     'Are the software maturity levels assigned to each software instance?',
  // },
  // {
  //   id: 20,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has SRS and SAD included',
  //   prompt:
  //     'Are the software requirements specifications (SRS) and software architecture design (SAD) properly referenced and included?',
  // },
  // {
  //   id: 21,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has SDD Referenced and Included',
  //   prompt:
  //     'Are the software design documents (SDD) properly referenced and included?',
  // },
  // {
  //   id: 22,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has SVR Referenced and Included',
  //   prompt:
  //     'Are the software verification reports (SVR) properly referenced and included?',
  // },
  // {
  //   id: 23,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has SCM and SCy Referenced and Included',
  //   prompt:
  //     'Are the software configuration management (SCM) and software cybersecurity (SCy) guidelines properly referenced and included?',
  // },
  // {
  //   id: 24,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has SID and PR guidelines Referenced and Included',
  //   prompt:
  //     'Are the software iterative development (SID) and software problem resolution (PR) guidelines properly referenced and included?',
  // },
  // {
  //   id: 25,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has SLC and SCR guidelines Referenced and Included',
  //   prompt:
  //     'Are the software life cycle (SLC) and software source code review (SCR) guidelines properly referenced and included?',
  // },
  // {
  //   id: 26,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has PSCA and SLNPS guidelines Referenced and Included',
  //   prompt:
  //     'Are the product software change assessment (PSCA) and software lifecycle for non-product software (SLNPS) guidelines properly referenced and included?',
  // },
  // {
  //   id: 27,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Regulatory Requirements Referenced and Included',
  //   prompt:
  //     'Are the regulatory requirements and regulatory MedDev glossary properly referenced and included?',
  // },
  // {
  //   id: 28,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has STP Referenced and Included',
  //   prompt:
  //     'Are the software test protocol (STP) and software test plan (STP) properly referenced and included?',
  // },
  // {
  //   id: 29,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has SVR and SVP Referenced and Included',
  //   prompt:
  //     'Are the software verification report (SVR) and software verification plan (SVP) properly referenced and included?',
  // },
  // {
  //   id: 30,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Software Test Cases Referenced and Included',
  //   prompt: 'Are the software test cases properly referenced and included?',
  // },
  // {
  //   id: 31,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Software Test Cycles Referenced and Included',
  //   prompt: 'Are the software test cycles properly referenced and included?',
  // },
  // {
  //   id: 32,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has Third-Party Software Referenced and Included',
  //   prompt:
  //     'Are the software third-party software (TPS) guidelines properly referenced and included?',
  // },
  // {
  //   id: 33,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has White Box and Black Box Testing Guidelines Referenced and Included',
  //   prompt:
  //     'Are the software white box testing (WBT) and black box testing (BBT) guidelines properly referenced and included?',
  // },
  // {
  //   id: 34,
  //   type: 'sop',
  //   reference: 'WI, Software Verification',
  //   name: 'Has BAT and ST Guidelines Referenced and Included',
  //   prompt:
  //     'Are the software build acceptance testing (BAT) and smoke testing (ST) guidelines properly referenced and included?',
  // },
];
export default GDP_CHECKS;
